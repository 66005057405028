import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { Product } from "../../../../../types/cockpit/types";
import { InviteLeadToProductDialog } from "../InviteLeadToProductDialog";
import { RowProps } from "../LeadList";
import { Divider } from "@mui/material";
import CCWorkflow from "./CCWorkflow";
import IsfpWorkflow from "./IsfpWorkflow";
import OPWorkflow from "./OPWorkflow";

export default function LeadWorkflow({ row }: { row: RowProps }) {
  const [inviteLeadToProductOpen, setInviteLeadToProductOpen] = useState(false);
  const [leadToProductInvite, setLeadToProductInvite] = useState<{ leadId: string; leadName: string; leadEmail: string; product: Product }>();

  const toggleInviteLeadToProductModal = () => {
    setInviteLeadToProductOpen(!inviteLeadToProductOpen);
  };

  const openInviteLeadModal = (product: Product) => {
    setLeadToProductInvite({ leadId: row.id, leadName: row.name, leadEmail: row.email, product });
    setInviteLeadToProductOpen(true);
  };

  return (
    <Box sx={{ flexGrow: 1, m: 4 }}>
      <Stack spacing={4} divider={<Divider orientation='horizontal' />}>
        <IsfpWorkflow row={row} openInviteLeadModal={openInviteLeadModal} />
        <CCWorkflow row={row} openInviteLeadModal={openInviteLeadModal} />
        <OPWorkflow row={row} openInviteLeadModal={openInviteLeadModal} />
      </Stack>
      <InviteLeadToProductDialog onClose={toggleInviteLeadToProductModal} open={inviteLeadToProductOpen} invite={leadToProductInvite} />
    </Box>
  );
}
