import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import { ConsumptionCertificate } from "../../../../types/ConsumptionCertificateState";
import ConsumptionCertificateCard from "../../consumption-certificate/ConsumptionCertificateCard";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface DisplayConsumptionCertificateDialogProps {
  consumptionCertificate?: ConsumptionCertificate;
  open: boolean;
  handleClose: () => void;
}

export function DisplayConsumptionCertificateDialog({ consumptionCertificate, open, handleClose }: DisplayConsumptionCertificateDialogProps) {
  if (!consumptionCertificate) return null;
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth>
      <Box sx={{ p: 2, backgroundColor: "#f6f6f6" }}>
        <DialogTitle>
          Verbrauchsausweise antworten
          <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ConsumptionCertificateCard cert={consumptionCertificate} />
        </DialogContent>
      </Box>
    </Dialog>
  );
}
