import { Box, CssBaseline, AppBar, Toolbar, Grid, Stack } from "@mui/material";
import NovoLogo from "./common/NovoLogo";
import React, { ReactNode } from "react";
import useImage from "../hooks/useImage";

export type ActionContainerProps = {
  children: ReactNode;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  sticker?: {
    image: string;
    t?: number;
    b?: number;
    l?: number;
    r?: number;
    s?: number;
  };
};

const ActionContainer: React.FC<ActionContainerProps> = ({ minWidth, minHeight, maxWidth, sticker, children }) => {
  const { image } = useImage(sticker?.image || "");
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#f6f6f6",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      <CssBaseline />
      <AppBar position='sticky' sx={{ backgroundColor: "background.default", top: 0 }}>
        <Toolbar>
          <NovoLogo height={48} sx={{ flexGrow: 1 }} />
          <Stack direction='row' alignItems='center' spacing={2} />
        </Toolbar>
      </AppBar>
      <Box sx={{ flex: 1, justifyContent: "center", alignItems: "center", display: "flex" }}>
        <Grid container>
          <Grid flexGrow={1} />
          <Grid>
            <Grid container>
              {!sticker && (
                <Grid sx={{ backgroundColor: "#fff", padding: 8, minHeight: minHeight ? minHeight : 400, minWidth: minWidth ? minWidth : 400, maxWidth }}>
                  {children}
                </Grid>
              )}
              {!!sticker && (
                <Grid
                  sx={{
                    backgroundColor: "#fff",
                    position: "relative",
                    padding: 8,
                    minHeight: minHeight ? minHeight : 400,
                    minWidth: minWidth ? minWidth : 400,
                    maxWidth,
                    "&::after": {
                      content: `url("${image}")`,
                      display: "block",
                      position: "absolute",
                      top: sticker.t !== undefined ? sticker.t + "%" : "",
                      left: sticker.l !== undefined ? sticker.l + "%" : "",
                      right: sticker.r !== undefined ? sticker.r + "%" : "",
                      bottom: sticker.b !== undefined ? sticker.b + "%" : "",
                      overflow: "hidden",
                      scale: sticker.s !== undefined ? sticker.s + "%" : "",
                    },
                  }}
                >
                  {children}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid flexGrow={1} />
        </Grid>
      </Box>
    </Box>
  );
};

export default ActionContainer;
