import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import NovoMultiSelect from "../../../../components/common/NovoMultiSelect";
import useFetchLeads from "../../../../hooks/useFetchLeads";
import { ConsumptionCertificateGenState, ConsumptionCertificate } from "../../../../types/ConsumptionCertificateState";
import { SchnellcheckQuestionnaire } from "../../../../types/Schnellcheck";
import { BubbleFlow, CCState, Isfp, IsfpState, Lead, OPState, Product, ProductRequest, Property } from "../../../../types/cockpit/types";
import LeadTable from "./LeadTable";
import { useSearchParams } from "react-router-dom";

export interface RowProps {
  id: string;
  name: string;
  address: string;
  properties?: Array<Property>;
  email: string;
  productInvites?: ProductRequest[];
  referrer?: string;
  cc: {
    state: CCState;
    id?: string;
    ccData?: ConsumptionCertificate;
    address?: string;
    bubbleCdnUrl?: string;
  };
  isfp: {
    state: IsfpState;
    address?: string;
    bubbleFlow?: BubbleFlow;
    bubbleCdnUrl?: string;
    schnellcheck?: SchnellcheckQuestionnaire;
  };
  op: {
    state: OPState;
    doubleFiles?: boolean;
    bubbleCdnUrl?: string;
    sanierungscheckKfw?: string;
    sanierungscheckSanierungspflicht?: string;
  };
}

export default function LeadList() {
  const { getLeads, leads, isLoading, error } = useFetchLeads();
  const [rows, setRows] = useState<Array<RowProps>>();
  const [ccFilters, setCcFilters] = useState<Array<boolean>>([]);
  const [isfpFilters, setIsfpFilters] = useState<Array<boolean>>([]);
  const [opFilters, setOpFilters] = useState<Array<boolean>>([]);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [filteredRows, setFilteredRows] = useState<Array<RowProps>>();

  useEffect(() => {
    getLeads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const processedRows = enrichRows(leads);
    setRows(processedRows);
  }, [leads]);

  const getFilterLabels = () => {
    const filterLabels: string[] = [];
    for (let i = 0; i < ccFilters.length; i++) {
      if (ccFilters[i]) {
        filterLabels.push(CCState.toString(i + 1)); // Shift right by 1 to ignore START states
      }
    }
    for (let i = 1; i < isfpFilters.length; i++) {
      if (isfpFilters[i]) {
        filterLabels.push(IsfpState.toString(i + 1));
      }
    }
    for (let i = 1; i < opFilters.length; i++) {
      if (isfpFilters[i]) {
        filterLabels.push(OPState.toString(i + 1));
      }
    }
    return filterLabels;
  };

  const onFilterUpdate = (value: Array<string>) => {
    const ccFilterValues = value.map((filterName) => CCState.fromString(filterName)).filter((state) => state !== undefined);
    const newCcFilters = CCState.all().map((state) => ccFilterValues.includes(state));
    setCcFilters(newCcFilters);

    const isfpFilterValues = value.map((filterName) => IsfpState.fromString(filterName)).filter((state) => state !== undefined);
    const newIsfpFilters = IsfpState.all().map((state) => isfpFilterValues.includes(state));
    setIsfpFilters(newIsfpFilters);

    const opFilterValues = value.map((filterName) => OPState.fromString(filterName)).filter((state) => state !== undefined);
    const newOpFilters = OPState.all().map((state) => opFilterValues.includes(state));
    setOpFilters(newOpFilters);
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  useEffect(() => {
    const stateFilter = (row: RowProps) => {
      // When no filters are applied, show all
      if (
        !ccFilters.reduce((acc, cur) => acc || cur, false) &&
        !isfpFilters.reduce((acc, cur) => acc || cur, false) &&
        !opFilters.reduce((acc, cur) => acc || cur, false)
      ) {
        return true;
      }
      return ccFilters[row.cc.state] || isfpFilters[row.isfp.state] || opFilters[row.op.state];
    };
    const searchFilter = (row: RowProps) => {
      if (!searchQuery) {
        return true;
      }
      const normalisedQuery = searchQuery.toLowerCase();
      return (
        row.name?.toLowerCase().includes(normalisedQuery) ||
        row.address?.toLowerCase().includes(normalisedQuery) ||
        row.email?.toLowerCase().includes(normalisedQuery) ||
        row.referrer?.toLocaleLowerCase().includes(normalisedQuery)
      );
    };
    setFilteredRows(rows?.filter(stateFilter).filter(searchFilter));
  }, [rows, ccFilters, isfpFilters, opFilters, searchQuery]);

  const handleRefresh = () => {
    getLeads();
  };

  return (
    <Box sx={{ flexGrow: 6, minHeight: 800, width: "100%", pt: 4 }}>
      <Stack spacing={2}>
        <TableTopActions onSearchChange={handleSearch} filters={getFilterLabels()} onFilterUpdate={onFilterUpdate} onRefresh={handleRefresh} />
        <LeadTable isLoading={isLoading} error={error} rows={filteredRows} />
      </Stack>
    </Box>
  );
}

type TableTopActionsProps = {
  onSearchChange: (value: string) => void;
  filters: string[];
  onFilterUpdate: (value: Array<string>) => void;
  onRefresh: () => void;
};
function TableTopActions({ onSearchChange, filters, onFilterUpdate, onRefresh }: TableTopActionsProps) {
  const [searchParams] = useSearchParams();
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const initialFilterVal = searchParams.get("filter") ?? "";

  useEffect(() => {
    if (initialFilterVal) {
      setAutocompleteValue(initialFilterVal);
    }
  }, [initialFilterVal]);

  useEffect(() => {
    onSearchChange(autocompleteValue);
  }, [autocompleteValue, onSearchChange]);

  const options = [
    ...IsfpState.all()
      .filter((state) => state !== IsfpState.START)
      .map((state) => IsfpState.toString(state)),
    ...CCState.all()
      .filter((state) => state !== CCState.START)
      .map((state) => CCState.toString(state)),
    ...OPState.all()
      .filter((state) => state !== OPState.START)
      .map((state) => OPState.toString(state)),
  ];

  const onAutocompleteChange = (_: React.SyntheticEvent, value: string) => {
    setAutocompleteValue(value);
  };

  return (
    <Box>
      <Stack direction={"row"} spacing={2}>
        <Autocomplete
          freeSolo
          renderInput={(params) => (
            <TextField
              data-cy='search-lead-input'
              {...params}
              label='Suchen'
              placeholder='Name, E-mail, etc...'
              InputProps={{
                ...params.InputProps,
                type: "search",
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          options={[]}
          onChange={onAutocompleteChange}
          value={autocompleteValue}
          sx={{ flexGrow: 4, bgcolor: "background.default" }}
        />
        <NovoMultiSelect
          label={"Filtern nach"}
          options={options}
          value={filters}
          onUpdate={onFilterUpdate}
          sx={{ flexGrow: 1, bgcolor: "background.default" }}
        />
        <Button data-cy='refresh-lead-list-btn' variant='outlined' color='secondary' sx={{ fontSize: 12 }} onClick={onRefresh}>
          <RefreshIcon />
        </Button>
      </Stack>
    </Box>
  );
}

function enrichRows(leads?: Lead[]): RowProps[] | undefined {
  if (!leads) {
    return;
  }
  return leads.map((lead) => {
    const cc = inflateCCData(lead);
    const isfp = inflateIsfpData(lead);
    const op = inflateOPData(lead);
    const address = isfp.address || cc.address || "";
    return {
      ...lead,
      address,
      isfp,
      cc,
      op,
    };
  });
}

interface IsfpCompletenessState {
  address: string;
  isStatusQuoComplete: boolean;
  hasOffer: boolean;
  isOfferAccepted: boolean;
  isDataComplete: boolean;
  isfpReady: boolean;
  hasStartedPath: boolean;
  isfp?: Isfp;
  schnellcheck?: SchnellcheckQuestionnaire;
  bubbleFlow?: BubbleFlow;
}

const isfpState = (lead: Lead): IsfpCompletenessState => {
  const isfp = lead.properties?.find((property) => !!property.isfp)?.isfp;
  const schnellcheck = isfp?.schnellcheck;
  const bubbleFlow = isfp?.bubbleFlow;

  const hasStartedPath = !!schnellcheck?.answers || !!bubbleFlow?.statusQuoResponse || !!lead.productInvites?.find((invite) => invite.product === Product.isfp);

  if (schnellcheck) {
    return {
      address: schnellcheck.answers.contact?.address ?? "",
      isStatusQuoComplete: !!schnellcheck.answers.houseOpenings,
      hasOffer: !!schnellcheck.answers.contact,
      isOfferAccepted: !!bubbleFlow?.renovationResponse,
      isDataComplete: !!bubbleFlow?.renovationResponse?.isCompleted,
      isfpReady: !!bubbleFlow?.renovationReport?.isfp,
      schnellcheck,
      bubbleFlow,
      hasStartedPath,
      isfp,
    };
  }
  if (bubbleFlow) {
    return {
      address: bubbleFlow.statusQuoResponse?.address ?? "",
      isStatusQuoComplete: !!bubbleFlow.statusQuoResponse?.isCompleted,
      hasOffer: !!bubbleFlow.offerRequest?.submitDate,
      isOfferAccepted: !!bubbleFlow.renovationResponse,
      isDataComplete: !!bubbleFlow.renovationResponse?.isCompleted,
      isfpReady: !!bubbleFlow.renovationReport?.isfp,
      bubbleFlow,
      hasStartedPath,
      isfp,
    };
  }
  return {
    address: "",
    isStatusQuoComplete: false,
    hasOffer: false,
    isOfferAccepted: false,
    isDataComplete: false,
    isfpReady: false,
    hasStartedPath,
    isfp,
  };
};

const inflateIsfpData = (lead: Lead) => {
  const { isfp, hasStartedPath, address, isStatusQuoComplete, hasOffer, isOfferAccepted, isDataComplete, isfpReady, bubbleFlow, schnellcheck } =
    isfpState(lead);

  if (isfpReady) {
    return { state: IsfpState.FINAL, address, bubbleCdnUrl: isfp!.bubbleFlow!.renovationReport!.isfp!, bubbleFlow, schnellcheck };
  } else if (isDataComplete) {
    return { state: IsfpState.RENOVATIONS, address, bubbleFlow, schnellcheck };
  } else if (isOfferAccepted) {
    return { state: IsfpState.OFFER_ACCEPTED, address, bubbleFlow, schnellcheck };
  } else if (hasOffer) {
    return { state: IsfpState.OFFER_REQUESTED, address, bubbleFlow, schnellcheck };
  } else if (isStatusQuoComplete) {
    return { state: IsfpState.STATUS_QUO, address, bubbleFlow, schnellcheck };
  } else if (hasStartedPath) {
    return { state: IsfpState.INVITED };
  } else {
    return { state: IsfpState.START };
  }
};

const inflateCCData = (lead: Lead) => {
  const consumptionCertificate = lead.properties?.find((property) => property.consumptionCertificate !== undefined)?.consumptionCertificate;
  const hasStartedPath = !!consumptionCertificate || !!lead.productInvites?.find((invite) => invite.product === Product.consumptionCertificate);
  const certificateReady = consumptionCertificate?.state === "final" && consumptionCertificate?.document.state === ConsumptionCertificateGenState.Ready;
  const properties = lead.properties ?? [];
  const document = properties.length > 0 ? properties[0].isfp?.bubbleFlow?.renovationReport?.energyCertificate : undefined;

  if (certificateReady || document) {
    return {
      state: CCState.FINAL,
      id: consumptionCertificate?.id,
      address: consumptionCertificate?.answers.address,
      ccData: consumptionCertificate,
      bubbleCdnUrl: document,
    };
  } else if (consumptionCertificate) {
    return { state: CCState.SUBMITTED, id: consumptionCertificate!.id, address: consumptionCertificate!.answers.address, ccData: consumptionCertificate };
  } else if (hasStartedPath) {
    return { state: CCState.INVITED };
  } else {
    return { state: CCState.START };
  }
};

const inflateOPData = (lead: Lead) => {
  /*
   * TODO
   * The whole Sanierungsgutachten workflow is not yet supported in the engine.
   * We can only track Invites but no Property objects are yet associated with the Lead
   */
  // const op = lead.properties?.find((property) => property.onePager !== undefined)?.onePager;
  const hasStartedPath = !!lead.productInvites?.find((invite) => invite.product === Product.onePager);
  const renovationReport = lead.properties && lead.properties.length > 0 ? lead.properties[0].isfp?.bubbleFlow?.renovationReport : undefined;
  const { isfpLight: bubbleCdnUrl, sanierungscheckKfw, sanierungscheckSanierungspflicht } = renovationReport ?? {};

  if (sanierungscheckKfw && sanierungscheckSanierungspflicht) {
    return { state: OPState.FINAL, sanierungscheckKfw, sanierungscheckSanierungspflicht, doubleFiles: true };
  }
  if (bubbleCdnUrl) {
    return { state: OPState.FINAL, bubbleCdnUrl, doubleFiles: false };
  }
  if (hasStartedPath) {
    return { state: OPState.INVITED };
  }
  return { state: OPState.START };
};
