export enum Steps {
  start = "",
  general = "#general",
  outerWalls = "#outer-walls",
  attic = "#attic",
  basement = "#basement",
  doorsWindows = "#doors-windows",
}

export const fromHash = (value: string): Steps => {
  switch (value) {
    case "":
      return Steps.start;
    case "#general":
      return Steps.general;
    case "#outer-walls":
      return Steps.outerWalls;
    case "#attic":
      return Steps.attic;
    case "#basement":
      return Steps.basement;
    case "#doors-windows":
      return Steps.doorsWindows;
    default:
      throw new Error(`"${value}" is not a valid value for Steps.`);
  }
};

export enum WallMaterials {
  brick = "Brick",
  concrete = "Concrete",
  wood = "Wooden frame",
  sandLimeBrick = "Sand-lime brick",
  hollowBrick = "Hollow brick",
  halfWood = "Half-wooden frame",
}

export enum InsulationMaterials {
  mineralWool = "Mineral wool",
  stoneWool = "Stone wool",
  styrofoam = "Styrofoam",
  woodFiber = "Wood fiber",
}

export enum WindowFrameMaterial {
  wood = "Wood",
  metal = "Metal",
  plastic = "Plastic",
}
