import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useState } from "react";

interface NovoMultiSelectProps extends Omit<AutocompleteProps<string, undefined, undefined, undefined>, "renderInput" | "value"> {
  label: string;
  value: Array<string>;
  error?: boolean;
  helperText?: string;
  onUpdate?: (value: Array<string>) => void;
}

export default function NovoMultiSelect({ label, value, onUpdate, error, helperText, ...props }: NovoMultiSelectProps) {
  const [_value, setValue] = useState(value || []);
  const name = label.toLowerCase().replace(" ", "-");

  const handler = (event: React.SyntheticEvent, value: Array<string>) => {
    setValue(value);
    if (onUpdate) {
      onUpdate(value);
    }
  };

  return (
    <Autocomplete
      multiple
      id={`${name}-tags-outlined`}
      value={_value}
      filterSelectedOptions
      onChange={handler}
      renderInput={(params) => <TextField {...params} label={label} error={error} helperText={helperText} />}
      {...props}
    />
  );
}
