import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import NovoLogo from "../../../../components/common/NovoLogo";
import CssBaseline from "@mui/material/CssBaseline";
import { Stack, Box, Typography, Divider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import IntroScreen from "./IntroScreen";
import { useLocation, useNavigate } from "react-router-dom";
import { Steps } from "./utils";
import ConsentSnackbar from "../Consent";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useImage from "../../../../hooks/useImage";
import GeneralInfoScreen from "./GeneralInfoScreen";
import OuterWallsScreen from "./OuterWallsScreen";
import AtticScreen from "./AtticScreen";
import BasementScreen from "./BasementScreen";
import DoorsAndWindowsScreen from "./DoorsAndWindowsScreen";

export type ScreenProps = {
  prev: () => void;
  next: () => void;
};

export default function RenovationsFunnel() {
  const { hash } = useLocation();
  const navigate = useNavigate();

  const next = () => {
    switch (hash) {
      case Steps.start:
        navigate(Steps.general);
        break;
      case Steps.general:
        navigate(Steps.outerWalls);
        break;
      case Steps.outerWalls:
        navigate(Steps.attic);
        break;
      case Steps.attic:
        navigate(Steps.basement);
        break;
      case Steps.basement:
        navigate(Steps.doorsWindows);
        break;
      default:
        navigate(Steps.start);
    }
  };

  const prev = () => {
    switch (hash) {
      case Steps.general:
        navigate(Steps.start);
        break;
      case Steps.outerWalls:
        navigate(Steps.general);
        break;
      case Steps.attic:
        navigate(Steps.outerWalls);
        break;
      case Steps.basement:
        navigate(Steps.attic);
        break;
      case Steps.doorsWindows:
        navigate(Steps.basement);
        break;
      default:
        navigate(Steps.start);
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#f4f5f6",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <AppBar position='sticky' sx={{ backgroundColor: "background.default", top: 0 }}>
        <Toolbar>
          <NovoLogo height={48} sx={{ flexGrow: 1 }} />
          <NovoLogo fileName='dena-logo.png' height={48} />
        </Toolbar>
      </AppBar>
      <Box sx={{ flexGrow: 1 }}>
        <Stack
          direction='column'
          spacing={2}
          sx={{
            maxWidth: "lg",
            mx: "auto",
            bgcolor: "background.default",
            px: { xs: 0, lg: 8 },
            pb: { xs: 0, lg: 4 },
          }}
        >
          <Title />
          <Divider />
          {hash === Steps.start && <IntroScreen prev={prev} next={next} />}
          {hash === Steps.general && <GeneralInfoScreen prev={prev} next={next} />}
          {hash === Steps.outerWalls && <OuterWallsScreen prev={prev} next={next} />}
          {hash === Steps.attic && <AtticScreen prev={prev} next={next} />}
          {hash === Steps.basement && <BasementScreen prev={prev} next={next} />}
          {hash === Steps.doorsWindows && <DoorsAndWindowsScreen prev={prev} next={next} />}
        </Stack>
      </Box>
      <ConsentSnackbar smartlookId='5abe1187b106b0cb2e77b582a599550690eca13f' metricName='Sanierungscheck' />
    </Box>
  );
}

function Title() {
  const kfwBafa = useImage("kfw-bafa.png");
  return (
    <Grid
      container
      spacing={2}
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      sx={{
        mx: 10,
        mt: 5,
      }}
    >
      <Grid xs={12}>
        <Typography
          variant='h4'
          sx={{
            fontWeight: "fontWeightBold",
            lineHeight: 1.4,
            pb: 0,
          }}
        >
          Welcome back to part 2 of the ISFP
          <br />
          Have your house documents at hand
        </Typography>
      </Grid>
      <Grid xs={4}>
        <Stack direction='row' spacing={1} sx={{ alignItems: "baseline", justifyContent: "center" }}>
          <CheckCircleOutlineIcon sx={{ alignSelf: "center", mr: 2 }} />
          <Typography>Sponsored by</Typography>
          <img src={kfwBafa.image} height={24} style={{ alignSelf: "center" }} />
        </Stack>
      </Grid>
      <Grid xs={4}>
        <Stack direction='row' spacing={1} sx={{ alignItems: "baseline", justifyContent: "center" }}>
          <CheckCircleOutlineIcon sx={{ alignSelf: "center", mr: 2 }} />
          <Typography>Over 90% interesting metrics</Typography>
        </Stack>
      </Grid>
      <Grid xs={4}>
        <Stack direction='row' spacing={1} sx={{ alignItems: "baseline", justifyContent: "center" }}>
          <CheckCircleOutlineIcon sx={{ alignSelf: "center", mr: 2 }} />
          <Typography>A 100% safe investment</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
