import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ChangeEvent, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Sophia from "./Sophia";
import ProgressBar from "../ProgressBar";
import { ScreenProps } from "./StatusQuoFunnel";
import HomeIcon from "@mui/icons-material/Home";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import HolidayVillageOutlinedIcon from "@mui/icons-material/HolidayVillageOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import { HouseType } from "../../../../types/Schnellcheck";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";
import { Steps } from "../../../../types/cockpit/types";

export default function Screen1({ schnellcheck, setSchnellcheck, isUpdateRunning, prev, saveAndNext }: ScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const conditionalRendering = () => {
    if (isMobile) {
      return;
    }
    return (
      <>
        <Grid sx={{ px: 0 }}>
          <Divider orientation='vertical' />
        </Grid>
        <Grid sx={{ flexGrow: 1 }}>
          <Sophia step={Steps.start} />
        </Grid>
      </>
    );
  };

  return (
    <Grid
      container
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mx: { xs: 0, lg: 10 },
        backgroundColor: "#f6f6f6",
        mb: 4,
        maxWidth: "100%",
      }}
    >
      <Grid xs={12}>
        <ProgressBar label={"Jetzt Starten!"} value={5} />
      </Grid>
      <Grid xs={12} lg={8}>
        <Form schnellcheck={schnellcheck} setSchnellcheck={setSchnellcheck} isUpdateRunning={isUpdateRunning} prev={prev} saveAndNext={saveAndNext} />
      </Grid>
      {conditionalRendering()}
    </Grid>
  );
}

function Form({ schnellcheck, setSchnellcheck, isUpdateRunning, prev, saveAndNext }: ScreenProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(false);
  const [yearError, setYearError] = useState<string>();
  const [areaError, setAreaError] = useState<string>();
  const [typeError, setTypeError] = useState<string>();
  const currentYear = new Date().getFullYear();

  const updateYear = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const yearOfConstruction = /^[0-9]+$/.test(value) ? Number.parseInt(value) : undefined;
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseDetails: {
          ...schnellcheck.answers?.houseDetails,
          yearOfConstruction,
        },
      },
    });
    if (yearOfConstruction) {
      setYearError(undefined);
    }
  };

  const updateArea = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const area = /^[0-9]+$/.test(value) ? Number.parseInt(value) : undefined;
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseDetails: {
          ...schnellcheck.answers?.houseDetails,
          area,
        },
      },
    });
    if (area) {
      setAreaError(undefined);
    }
  };

  const handleTypeSelection = (type: HouseType) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseDetails: {
          ...schnellcheck.answers?.houseDetails,
          type,
        },
      },
    });
  };

  const handleListedBuilding = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseDetails: {
          ...schnellcheck.answers?.houseDetails,
          isMonument: event.target.checked,
        },
      },
    });
  };

  const validate = () => {
    const { area, yearOfConstruction, type } = schnellcheck.answers?.houseDetails ?? {};
    if (!yearOfConstruction) {
      setYearError("Pflichtfeld");
      return false;
    } else if (yearOfConstruction < 1800 || yearOfConstruction > currentYear) {
      setYearError(`Das Jahr muss zwischen 1800 und ${currentYear} liegen`);
      return false;
    } else {
      setYearError(undefined);
    }

    if (!area) {
      setAreaError("Pflichtfeld");
      return false;
    } else if (area <= 0) {
      setAreaError("Muss größer als 0 sein");
      return false;
    } else {
      setAreaError(undefined);
    }

    if (!type) {
      setTypeError("Pflichtfeld");
      return false;
    } else {
      setTypeError(undefined);
    }
    return true;
  };

  const renderDialogLink = () => {
    const toggleOpen = () => {
      setOpen(!open);
    };

    if (isDesktop) {
      return;
    }
    return (
      <Grid xs={12} lg={0} sx={{ mt: 2 }}>
        <Link
          variant='caption'
          onClick={toggleOpen}
          sx={{
            color: "text.primary",
            textDecorationColor: "rgba(0, 0, 0, 0.87)",
            fontWeight: "fontWeightMedium",
          }}
        >
          Warum benötigen wir diese Daten?
        </Link>
        <Dialog open={open} onClose={toggleOpen} fullScreen>
          <DialogTitle>
            <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={toggleOpen}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Sophia step={Steps.start} />
          </DialogContent>
        </Dialog>
      </Grid>
    );
  };

  return (
    <Grid
      container
      spacing={{
        xs: 2,
        lg: 4,
      }}
      sx={{
        ml: { lg: 4 },
        px: { xs: 2 },
        mx: { xs: 0, lg: "inherit" },
      }}
    >
      <Grid xs={12} sx={{ mb: 2 }}>
        <Typography variant='body1'>
          <strong>Schritt 1: </strong>Informationen zum Haus
        </Typography>
      </Grid>
      <Grid xs={12} md={6}>
        <FormControl sx={{ "& > div > div.MuiInputBase-root": { backgroundColor: "#FFFFFF" }, width: "100%" }}>
          <TextField
            variant='outlined'
            id='build-year-input'
            data-cy='build-year-input'
            value={schnellcheck.answers?.houseDetails?.yearOfConstruction}
            label='Baujahr'
            onChange={updateYear}
            required={true}
            type='number'
            inputProps={{ min: 1800, max: currentYear }}
            error={!!yearError}
            helperText={yearError}
            InputLabelProps={{ shrink: !!schnellcheck.answers?.houseDetails?.yearOfConstruction }}
          />
        </FormControl>
      </Grid>
      <Grid xs={12} md={6}>
        <FormControl sx={{ "& > div > div.MuiInputBase-root": { backgroundColor: "#FFFFFF" }, width: "100%" }}>
          <TextField
            variant='outlined'
            id='area-input'
            data-cy='area-input'
            value={schnellcheck.answers?.houseDetails?.area}
            label='Wohnfläche'
            onChange={updateArea}
            required={true}
            type='number'
            inputProps={{ min: 1 }}
            error={!!areaError}
            helperText={areaError}
            InputProps={{
              endAdornment: <InputAdornment position='end'>m²</InputAdornment>,
            }}
            InputLabelProps={{ shrink: !!schnellcheck.answers?.houseDetails?.area }}
          />
        </FormControl>
      </Grid>
      <Grid xs={12}>
        <TypeSelector selectedType={schnellcheck.answers?.houseDetails?.type} typeError={typeError} handleTypeSelection={handleTypeSelection} />
      </Grid>
      <Grid xs={12}>
        <FormControlLabel
          control={<Checkbox onChange={handleListedBuilding} checked={schnellcheck.answers?.houseDetails?.isMonument} />}
          label='Dies ist ein denkmalgeschütztes Gebäude.'
        />
      </Grid>
      {renderDialogLink()}
      <Grid xs={12} sx={{ mt: { xs: 2, lg: 8 } }}>
        <Stack direction={"row"} alignItems={"flex-start"}>
          <Button variant='text' color='secondary' onClick={prev}>
            ZURÜCK
          </Button>
          <Box sx={{ flexGrow: 1 }}>
            <ButtonWithSpinner
              variant='contained'
              sx={{ float: "inline-end" }}
              loading={isUpdateRunning}
              data-cy='screen-1-next-btn'
              onClick={() => {
                if (validate()) {
                  saveAndNext();
                }
              }}
              label={"WEITER"}
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}

function TypeSelector({
  selectedType,
  typeError,
  handleTypeSelection,
}: {
  selectedType?: HouseType;
  typeError?: string;
  handleTypeSelection: (type: HouseType) => void;
}) {
  return (
    <FormControl required error={!!typeError}>
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          borderRadius: 1,
          borderWidth: typeError ? 1 : 0,
          borderStyle: typeError ? "solid" : "",
          borderColor: "error.main",
        }}
      >
        <Grid xs={6} md={4}>
          <Stack
            component={Paper}
            direction={"row"}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", backgroundColor: selectedType === HouseType.singleFamily ? "" : "inherit" }}
            onClick={() => handleTypeSelection(HouseType.singleFamily)}
          >
            <Stack alignItems={"start"} spacing={1}>
              {selectedType === HouseType.singleFamily && <HomeIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              {selectedType !== HouseType.singleFamily && <HomeOutlinedIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Einfamilienhaus
              </Typography>
            </Stack>
            <Checkbox
              sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }}
              onChange={() => handleTypeSelection(HouseType.singleFamily)}
              checked={selectedType === HouseType.singleFamily}
              data-cy='single-family-option'
            />
          </Stack>
        </Grid>
        <Grid xs={6} md={4}>
          <Stack
            component={Paper}
            direction={"row"}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", backgroundColor: selectedType === HouseType.doubleFamily ? "" : "inherit" }}
            onClick={() => handleTypeSelection(HouseType.doubleFamily)}
          >
            <Stack alignItems={"start"} spacing={1}>
              {selectedType === HouseType.doubleFamily && <HolidayVillageIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              {selectedType !== HouseType.doubleFamily && <HolidayVillageOutlinedIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Zweifamilienhaus
              </Typography>
            </Stack>
            <Checkbox
              sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }}
              onChange={() => handleTypeSelection(HouseType.doubleFamily)}
              checked={selectedType === HouseType.doubleFamily}
              data-cy='double-family-option'
            />
          </Stack>
        </Grid>
        <Grid xs={6} md={4}>
          <Stack
            component={Paper}
            direction={"row"}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", backgroundColor: selectedType === HouseType.multiFamily ? "" : "inherit" }}
            onClick={() => handleTypeSelection(HouseType.multiFamily)}
          >
            <Stack alignItems={"start"} spacing={1}>
              {selectedType === HouseType.multiFamily && <ApartmentIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              {selectedType !== HouseType.multiFamily && <ApartmentOutlinedIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Mehrfamilienhaus
              </Typography>
            </Stack>
            <Checkbox
              sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }}
              onChange={() => handleTypeSelection(HouseType.multiFamily)}
              checked={selectedType === HouseType.multiFamily}
              data-cy='multi-family-option'
            />
          </Stack>
        </Grid>
        <Grid xs={12} sx={{ mt: { xs: -1, lg: -2 } }}>
          {typeError && <FormHelperText>{typeError}</FormHelperText>}
        </Grid>
      </Grid>
    </FormControl>
  );
}
