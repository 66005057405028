import Grid from "@mui/material/Unstable_Grid2";
import { ConsumptionCertificate } from "../../../types/ConsumptionCertificateState";
import Card from "@mui/material/Card";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

type ConsumptionCertificateCardProps = {
  cert?: ConsumptionCertificate;
};

interface SectionTitleProps extends BoxProps {
  open?: boolean;
}

const CollapsibleSectionTitle = styled(Box)<SectionTitleProps>(({ open = false }) => ({
  "&:before": {
    content: open ? '"⏷"' : '"⏵"',
    cursor: "pointer",
    display: "inline-block",
    marginRight: "0.5rem",
    verticalAlign: "text-bottom",
  },
  "& .MuiTypography-root": {
    display: "inline-block",
    cursor: "pointer",
  },
}));

export default function ConsumptionCertificateCard({ cert }: ConsumptionCertificateCardProps) {
  const [openGeneral, setOpenGeneral] = useState(false);
  const [openHeating, setOpenHeating] = useState(false);
  const [openHotWater, setOpenHotWater] = useState(false);

  const clickGeneral = () => {
    setOpenGeneral(!openGeneral);
  };

  const clickHeating = () => {
    setOpenHeating(!openHeating);
  };

  const clickHotWater = () => {
    setOpenHotWater(!openHotWater);
  };

  return (
    <Card>
      <Grid container spacing={2} m={2}>
        <Grid xs={12}>
          <Typography variant='h5'>Gebäude</Typography>
        </Grid>
        <Grid xs={4}>
          <Typography variant='overline'>Anlass</Typography>
          <Typography variant='body2' fontWeight={600}>
            {cert?.answers.reason}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography variant='overline'>Gebäudetyp</Typography>
          <Typography variant='body2' fontWeight={600}>
            {cert?.answers.typeOfBuilding}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography variant='overline'>Anzahl Wohnungen</Typography>
          <Typography variant='body2' fontWeight={600}>
            {cert?.answers.numberOfApartments}
          </Typography>
        </Grid>
        <Grid xs={2} />
        <Grid xs={4} />
        <Grid xs={2}>
          <Typography variant='overline'>Baujahr</Typography>
          <Typography variant='body2' fontWeight={600}>
            {cert?.answers.yearOfConstruction}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography variant='overline'>Wärmeschutzverordnung</Typography>
          <Typography variant='body2' fontWeight={600}>
            {cert?.answers.reform}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography variant='overline'>Wohnfläche</Typography>
          <Typography variant='body2' fontWeight={600}>
            {cert?.answers.area} m²
          </Typography>
        </Grid>
        <Grid xs={2} />
        <Grid xs={4}>
          <Typography variant='overline'>Straße & Hausnummer</Typography>
          <Typography variant='body2' fontWeight={600}>
            {cert?.answers.address}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography variant='overline'>PLZ</Typography>
          <Typography variant='body2' fontWeight={600}>
            {cert?.answers.postalCode}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography variant='overline'>Ort</Typography>
          <Typography variant='body2' fontWeight={600}>
            {cert?.answers.location}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography variant='overline'>Bundesland</Typography>
          <Typography variant='body2' fontWeight={600}>
            {cert?.answers.bundesland}
          </Typography>
        </Grid>
        <Grid xs={2} />
        <Grid xs={12} mt={2}>
          <CollapsibleSectionTitle open={openGeneral} onClick={clickGeneral}>
            <Typography variant='subtitle1' onClick={clickGeneral}>
              Allgemein
            </Typography>
          </CollapsibleSectionTitle>
        </Grid>
        <Grid xs={12}>
          <Collapse in={openGeneral}>
            <Grid container spacing={2} mx={2}>
              <Grid xs={4}>
                <Typography variant='overline'>Art der Lüftung</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.ventilationType?.join() || "-"}
                </Typography>
              </Grid>
              <Grid xs={4}>
                <Typography variant='overline'>Kühlung</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.coolingSystems?.map((cooling) => `${cooling.system}: ${cooling.surface}m²`).join() ?? "-"}
                </Typography>
              </Grid>
              <Grid xs={4}>
                <Typography variant='overline'>Erneuerbare Energien</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.renewable?.join() || "-"}
                </Typography>
              </Grid>
              <Grid xs={12}>
                <Typography variant='overline'>Sanierungsmaßnahmen</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.renovationMeasures}
                </Typography>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
        <Grid xs={12}>
          <CollapsibleSectionTitle open={openHeating} onClick={clickHeating}>
            <Typography variant='subtitle1' onClick={clickHeating}>
              Heizung
            </Typography>
          </CollapsibleSectionTitle>
        </Grid>
        <Grid xs={12}>
          <Collapse in={openHeating}>
            <Grid container spacing={2} mx={2}>
              <Grid xs={4}>
                <Typography variant='overline'>Heizsystem</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.heatingSystemType}
                </Typography>
              </Grid>
              <Grid xs={1}>
                <Typography variant='overline'>Monat</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.monthOfBill}
                </Typography>
              </Grid>
              <Grid xs={1}>
                <Typography variant='overline'>Jahr</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.yearOfBill}
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Typography variant='overline'>Verbrauch</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.verbrauch1}
                </Typography>
              </Grid>
              <Grid xs={4} />
              <Grid xs={4}>
                <Typography variant='overline'>Jahr der Installation</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.yearOfHeatingInstalation}
                </Typography>
              </Grid>
              <Grid xs={1}>
                <Typography variant='overline'>Monat</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.monthOfBill}
                </Typography>
              </Grid>
              <Grid xs={1}>
                <Typography variant='overline'>Jahr</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert ? cert.answers.yearOfBill! - 1 : ""}
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Typography variant='overline'>Verbrauch</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.verbrauch2}
                </Typography>
              </Grid>
              <Grid xs={4} />
              <Grid xs={4} />
              <Grid xs={1}>
                <Typography variant='overline'>Monat</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.monthOfBill}
                </Typography>
              </Grid>
              <Grid xs={1}>
                <Typography variant='overline'>Jahr</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert ? cert.answers.yearOfBill! - 2 : ""}
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Typography variant='overline'>Verbrauch</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.verbrauch3}
                </Typography>
              </Grid>
              <Grid xs={4} />
              <Grid xs={12} />
              <Grid xs={4}>
                <Typography variant='overline'>Zweites Heizsystem</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.secondaryHeatingSystemType}
                </Typography>
              </Grid>
              <Grid xs={1}>
                <Typography variant='overline'>Monat</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.monthOfBill}
                </Typography>
              </Grid>
              <Grid xs={1}>
                <Typography variant='overline'>Jahr</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.yearOfBill}
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Typography variant='overline'>Verbrauch</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.secondaryVerbrauch1}
                </Typography>
              </Grid>
              <Grid xs={4} />
              <Grid xs={4}>
                <Typography variant='overline'>Jahr der Installation</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.secondaryYearOfHeatingInstalation}
                </Typography>
              </Grid>
              <Grid xs={1}>
                <Typography variant='overline'>Monat</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.monthOfBill}
                </Typography>
              </Grid>
              <Grid xs={1}>
                <Typography variant='overline'>Jahr</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert ? cert.answers.yearOfBill! - 1 : ""}
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Typography variant='overline'>Verbrauch</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.secondaryVerbrauch2}
                </Typography>
              </Grid>
              <Grid xs={4} />
              <Grid xs={4} />
              <Grid xs={1}>
                <Typography variant='overline'>Monat</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.monthOfBill}
                </Typography>
              </Grid>
              <Grid xs={1}>
                <Typography variant='overline'>Jahr</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert ? cert.answers.yearOfBill! - 2 : ""}
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Typography variant='overline'>Verbrauch</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.secondaryVerbrauch3}
                </Typography>
              </Grid>
              <Grid xs={4} />
            </Grid>
          </Collapse>
        </Grid>
        <Grid xs={12}>
          <CollapsibleSectionTitle open={openHotWater} onClick={clickHotWater}>
            <Typography variant='subtitle1' onClick={clickHotWater}>
              Warmwasserdaten
            </Typography>
          </CollapsibleSectionTitle>
        </Grid>
        <Grid xs={12}>
          <Collapse in={openHotWater}>
            <Grid container spacing={2} mx={2}>
              {cert?.answers.hotWaterSystems.map((system, index) => (
                <Grid xs={4} key={index}>
                  <Stack>
                    <Typography variant='overline'>Warmwassersystem {index + 1}</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {system.type}
                    </Typography>
                    {system.isPartOfHeating && (
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <CheckCircleIcon sx={{ color: "success.main" }} />
                        <Typography variant='body2' sx={{ color: "success.main" }}>
                          Identisch mit dem Heizsystem
                        </Typography>
                      </Stack>
                    )}
                    {!system.isPartOfHeating && (
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <CancelIcon sx={{ color: "text.secondary" }} />
                        <Typography variant='body2' sx={{ color: "text.secondary" }}>
                          Identisch mit dem Heizsystem
                        </Typography>
                      </Stack>
                    )}
                    {!system.isPartOfHeating && system.isCentralized && (
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <CheckCircleIcon sx={{ color: "success.main" }} />
                        <Typography variant='body2' sx={{ color: "success.main" }}>
                          Zentrale Warmwassererwärmung
                        </Typography>
                      </Stack>
                    )}
                    {!system.isPartOfHeating && !system.isCentralized && (
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <CancelIcon sx={{ color: "text.secondary" }} />
                        <Typography variant='body2' sx={{ color: "text.secondary" }}>
                          Zentrale Warmwassererwärmung
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Card>
  );
}
