import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ScreenProps } from "./RenovationsFunnel";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Sophia from "./Sophia";
import { Steps } from "./utils";
import ProgressBar from "../ProgressBar";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useImage from "../../../../hooks/useImage";

export default function GeneralInfoScreen({ prev, next }: ScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const conditionalRendering = () => {
    if (isMobile) {
      return;
    }
    return (
      <>
        <Grid sx={{ px: 0 }}>
          <Divider orientation='vertical' />
        </Grid>
        <Grid sx={{ flexGrow: 1 }}>
          <Sophia step={Steps.general} />
        </Grid>
      </>
    );
  };

  return (
    <Grid
      container
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mb: 4,
        maxWidth: "100%",
      }}
    >
      <Grid xs={12}>
        <ProgressBar label={"Jetzt Starten!"} value={5} />
      </Grid>
      <Grid xs={12} lg={8}>
        <Form prev={prev} next={next} />
      </Grid>
      {conditionalRendering()}
    </Grid>
  );
}

function Form({ prev, next }: ScreenProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(false);

  const renderDialogLink = () => {
    const toggleOpen = () => {
      setOpen(!open);
    };

    if (isDesktop) {
      return;
    }
    return (
      <Grid xs={12} lg={0} sx={{ mt: 2 }}>
        <Link
          variant='caption'
          onClick={toggleOpen}
          sx={{
            color: "text.primary",
            textDecorationColor: "rgba(0, 0, 0, 0.87)",
            fontWeight: "fontWeightMedium",
          }}
        >
          Warum benötigen wir diese Daten?
        </Link>
        <Dialog open={open} onClose={toggleOpen} fullScreen>
          <DialogTitle>
            <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={toggleOpen}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Sophia step={Steps.general} />
          </DialogContent>
        </Dialog>
      </Grid>
    );
  };

  return (
    <Grid
      container
      spacing={{
        xs: 2,
        lg: 4,
      }}
      sx={{
        ml: { lg: 4 },
        px: { xs: 2 },
        mx: { xs: 0, lg: "inherit" },
      }}
    >
      <Grid xs={12} sx={{ mb: 2 }}>
        <Typography variant='body1'>
          <strong>Screen 1: </strong>General House information
        </Typography>
      </Grid>
      <Grid xs={12}>
        <TypeSelector />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField fullWidth variant='outlined' id='house-units-input' label='House units' required={true} type='number' inputProps={{ min: 1, max: 20 }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField fullWidth variant='outlined' id='number-of-floors-input' label='Floors' required={true} type='number' inputProps={{ min: 1, max: 20 }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField
          fullWidth
          variant='outlined'
          id='floor-height-input'
          label='Floor height'
          required={true}
          type='number'
          inputProps={{ min: 150, max: 400 }}
          InputProps={{
            endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
          }}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <Stack direction='row' alignItems='baseline' spacing={2}>
          <Typography>Do you already have expansion plans?</Typography>
          <ToggleButtonGroup size='small' exclusive>
            <ToggleButton value='yes'>Yes</ToggleButton>
            <ToggleButton value='no'>No</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Grid>
      {renderDialogLink()}
      <Grid xs={12} sx={{ mt: { xs: 2, lg: 8 } }}>
        <Stack direction={"row"} justifyContent='space-between'>
          <Button variant='text' color='secondary' onClick={prev}>
            Zurück
          </Button>
          <Button variant='contained' onClick={next}>
            Weiter
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

function TypeSelector() {
  const detached = useImage("Detached.png");
  const semi = useImage("Semi.png");
  const terraced = useImage("Terraced.png");
  return (
    <FormControl fullWidth>
      <Grid
        container
        flexGrow={1}
        spacing={{
          xs: 2,
          lg: 4,
        }}
      >
        <Grid xs={6} md={4}>
          <Stack component={Paper} direction='row' alignItems='center' sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", height: "100%" }}>
            <Stack alignItems={"start"} spacing={1}>
              <img src={detached.image} width={40} height={40} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Detached house
              </Typography>
            </Stack>
            <Checkbox sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }} />
          </Stack>
        </Grid>
        <Grid xs={6} md={4}>
          <Stack component={Paper} direction='row' alignItems='center' sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", height: "100%" }}>
            <Stack alignItems={"start"} spacing={1}>
              <img src={semi.image} width={50} height={50} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Semidetached
              </Typography>
            </Stack>
            <Checkbox sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }} />
          </Stack>
        </Grid>
        <Grid xs={6} md={4}>
          <Stack component={Paper} direction={"row"} alignItems='center' sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", height: "100%" }}>
            <Stack alignItems={"start"} spacing={1}>
              <img src={terraced.image} width={60} height={60} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Terraced house
              </Typography>
            </Stack>
            <Checkbox sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }} />
          </Stack>
        </Grid>
      </Grid>
    </FormControl>
  );
}
