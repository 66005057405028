import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import EmailIcon from "@mui/icons-material/Email";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

export default function EmailRequestRequired({
  description = "In Ihrem Fall ist ein Bedarfsausweis verpflichtend und ein Verbrauchsausweis leider nicht ausreichend. Wir kümmern uns gern darum! Kontaktieren Sie uns per E-Mail und wir unterstützen Sie bei den nächsten Schritten.",
  subject = "Neuen%20Bedarfsausweis%20beantragen",
  body = "",
}: {
  description?: string;
  subject?: string;
  body?: string;
}) {
  const HighlightPanel = styled(Stack)(({ theme }) => ({
    "@keyframes fadeOut": {
      from: {
        backgroundColor: `rgb(from ${theme.palette.primary.light} r g b / 1.0)`,
        margin: "-2rem",
        padding: "2rem",
      },
      to: {
        backgroundColor: `rgb(from ${theme.palette.primary.light} r g b / 0)`,
        margin: 0,
        padding: 0,
      },
    },
    animation: "fadeOut 1.5s ease-out",
  }));

  return (
    <HighlightPanel spacing={2}>
      <Typography variant='body1'>{description}</Typography>
      <Button variant='contained' href={`mailto:hi@buildingnovo.com?subject=${subject}&body=${body}`} sx={{ alignSelf: "flex-start" }}>
        <EmailIcon sx={{ verticalAlign: "middle", mr: 2 }} />
        E-Mail Senden
      </Button>
    </HighlightPanel>
  );
}
