import { Button, Grid, Link, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ChangeEvent, FunctionComponent, MouseEvent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ContactCard } from "./ContactCard";
import pic from "../../imgs/sophia_pic.png";
import useUpsertQuestionnaire from "../../hooks/useUpsertQuestionnaire";
import { CONTACT } from "./storage";
import usePutMetric from "../../hooks/usePutMetric";
import { isValidEmail } from "../../pages/auth/emailValidator";

type ContactState = {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  callMode: string;
};

type ContactProps = {
  question: number;
  setNextQuestion: (questionNbr: number) => void;
};

export const Contact: FunctionComponent<ContactProps> = ({ setNextQuestion }) => {
  const [putMetric] = usePutMetric();
  const [upsertQuestionnaire] = useUpsertQuestionnaire();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [contactState, setContactState] = useState<ContactState>({
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    callMode: "",
  });

  function firstNameOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newReportState = {
      ...contactState,
      firstName: e.target.value,
    };
    setContactState(newReportState);
    localStorage.setItem(CONTACT, JSON.stringify(newReportState));
    setDisabled(sendShouldBeDisabled(newReportState));
  }

  function lastNameOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newReportState = {
      ...contactState,
      lastName: e.target.value,
    };
    setContactState(newReportState);
    localStorage.setItem(CONTACT, JSON.stringify(newReportState));
    setDisabled(sendShouldBeDisabled(newReportState));
  }

  function emailOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newReportState = {
      ...contactState,
      email: e.target.value,
    };
    setContactState(newReportState);
    localStorage.setItem(CONTACT, JSON.stringify(newReportState));
    setDisabled(sendShouldBeDisabled(newReportState));
  }

  const sendShouldBeDisabled = (newContactState: ContactState) =>
    !isValidEmail(newContactState.email) || !newContactState.telephone || !newContactState.firstName || !newContactState.lastName;

  function telephoneOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newReportState = {
      ...contactState,
      telephone: e.target.value,
    };
    setContactState(newReportState);
    localStorage.setItem(CONTACT, JSON.stringify(newReportState));
    setDisabled(sendShouldBeDisabled(newReportState));
  }

  useEffect(() => {
    // Local Storage
    const answer = localStorage.getItem(CONTACT);
    if (answer) {
      const parsedAnswer = JSON.parse(answer);
      setContactState(parsedAnswer);
      setDisabled(sendShouldBeDisabled(parsedAnswer));
    }
  }, []);

  function sendQuestionnaireOnClick(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    upsertQuestionnaire();
    if (contactState.callMode === "Book a call") {
      window.open(
        "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0J9MgxewrrYlWI-9tUZCKAYL8cxIFqgZmzVv_quBQnTA5mpKNabYgeF25A8O4mO5rEfxnkjlrk",
        "_blank",
        "noreferrer",
      );
    }
    setNextQuestion(6);
    putMetric("ZuGuEmbedded", "OfferRequested");
  }

  return (
    <>
      <Grid
        container
        paddingY={6}
        paddingX={8}
        spacing={6}
        sx={{
          paddingY: {
            xs: 3,
            md: 6,
          },
          paddingX: {
            xs: 3,
            md: 8,
          },
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            marginBottom: {
              sm: "80px",
            },
          }}
        >
          <Grid item container marginBottom={6}>
            <Typography component='h2' sx={{ fontSize: 24, fontWeight: 700, mb: "20px" }}>
              Profitieren Sie von den Vorteilen des individuellen Sanierungsfahrplans (iSFP)
            </Typography>
            <Typography sx={{ fontSize: 20, fontWeight: 500, mb: "20px" }}>
              In Kooperation mit dem Energieberater-Team von NOVO erstellen wir Ihr Angebot für den iSFP.
            </Typography>
            <Typography sx={{ fontSize: 20, fontWeight: 500 }}>So sparen Sie ab der ersten Sanierungsmaßnahme.</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={4}>
              <img alt='Sophia Hartley' src={pic} width={"100%"} />
            </Grid>
            <Grid item container direction={"column"} xs={8} justifyContent={"flex-end"}>
              <Grid item>
                <Typography sx={{ fontWeight: 700, fontSize: 14, color: "#000000" }}>Ihre NOVO Energieberatung</Typography>
                <Typography sx={{ fontWeight: 400, fontSize: 14, color: "#000000" }}>Sophia Hartley</Typography>
                <Link
                  href='https://calendar.app.google/Nfx7DnxqQck45RS77'
                  target='_blank'
                  color={"inherit"}
                  sx={{ fontWeight: 400, fontSize: 14, color: "#000000" }}
                >
                  Gesprächstermin buchen
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ContactCard
            firstName={contactState.firstName}
            firstNameOnChange={firstNameOnChange}
            lastName={contactState.lastName}
            lastNameOnChange={lastNameOnChange}
            email={contactState.email}
            emailOnChange={emailOnChange}
            telephone={contactState.telephone}
            telephoneOnChange={telephoneOnChange}
            sendQuestionnaireFunc={sendQuestionnaireOnClick}
            buttonDisabled={disabled}
          />
        </Grid>
        <Grid item>
          <Box>
            <Button
              variant='text'
              size='small'
              onClick={() => setNextQuestion(4)}
              sx={{ color: "#c7c7c7", textDecorationLine: "underline", ":hover": { backgroundColor: "transparent" } }}
            >
              <FormattedMessage id='app.openings.back' />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
