import { Lead } from "./cockpit/types";

export enum ConsumptionCertificateGenState {
  Ready = "ready",
  Generating = "generating",
  Unavailable = "unavailable",
}

export type ConsumptionCertificateDocument = {
  state: ConsumptionCertificateGenState;
  // Registrierungsnummer → number generated automatically by the DIBt service; only available
  // when PDF is in final state and PDF was successfully generated
  registrationNumber?: string;
};

export enum ConsumptionCertOrderPaymentStatus {
  Paid = "paid",
  Canceled = "canceled",
  Failed = "failed",
  NoPaymentRequired = "no_payment_required",
  OtherNotReady = "other_not_ready",
}

export type ConsumptionCertificateOrderData = { id: string; payment: { status?: ConsumptionCertOrderPaymentStatus } };

export type ConsumptionCertificate = {
  id: string;
  answers: ConsumptionCertificateState;
  document: ConsumptionCertificateDocument;
  purchaseOrder?: ConsumptionCertificateOrderData;
  lead?: Lead;
  state?: "submitted" | "final";
  lastUpdatedAt: number;
  createdAt: number;
};

export type ConsumptionCertificateItem = {
  id: string;
  answers: ConsumptionCertificateState;
  document: ConsumptionCertificateDocument;
  state?: "submitted" | "final";
  lastUpdatedAt: number;
  createdAt: number;
};

export enum ComponentEquipmentEnum {
  BuildingSides = "Abseiten",
  SystemControl = "Anlagenregelung",
  ExternalWallAgainstExternalAir = "Außenwand gg. Außenluft",
  ExternalWallAgainstEarth = "Außenwand gg. Erdreich",
  Lighting = "Beleuchtung",
  HumidificationDehumidification = "Be-/Entfeuchtung",
  FloorAgainstEarth = "Boden gegen Erdreich",
  FloorAgainstExternalAir = "Boden gg. Außenluft",
  Roof = "Dach",
  RoofWindows = "Dachfenster",
  EntranceDoor = "Eingangstür",
  Windows = "Fenster",
  Dormers = "Gauben",
  BuildingAutomation = "Gebäudeautomation",
  Heating = "Heizung",
  CoolingGeneration = "Kälteerzeugung",
  CoolingDistributionRelease = "Kälteverteilung / -abgabe",
  BasementCeiling = "Kellerdecke",
  Cooling = "Kühlung",
  AirLeakage = "Luftundichtigkeiten",
  AirDistributionRelease = "Luftverteilung / -abgabe",
  Ventilation = "Lüftung",
  VentilationSystem = "Lüftungsanlage",
  VentilationConcept = "Lüftungskonzept",
  SideDoor = "Nebentür",
  TopFloorCeiling = "oberste Geschossdecke",
  RollerShuttersAndBoxes = "Rollläden und -kästen",
  Other = "Sonstiges",
  HeatDistributionRelease = "Wärmeverteilung / -abgabe",
  HeatGenerator = "Wärmeerzeuger",
  HotWaterPreparation = "Warmwasserbereitung",
}

export enum RenovationCombiEnum {
  TogetherWithOtherRenovations = "in Zusammenhang mit größerer Modernisierung",
  AsIndividualMeasure = "als Einzelmaßnahme",
}

export type ModernisationRecommendationState = {
  // XML field: 'Nummer'; sequential number of the recommendation, starting at 1
  number?: number;
  // XML Field: 'BauteilAnlagenteil'; which part of the building that should be renovated
  componentEquipment?: ComponentEquipmentEnum;
  // XML Field: 'Massnahmenbeschreibung'; detailed explanation of renovation measures
  description?: string;
  // XML Field: 'Modernisierungskombination'; if the renovation should be taken as a standalone measure or in combination with others
  renovationCombination?: RenovationCombiEnum;
  // XML Field: 'Amortisation'; Amortisation time of the recommended renovation
  amortisation?: string;
  // XML Field: spezifischeKosten; approximation of how much each spared KW/h due to the renovation would cost
  specificCosts?: string;
};

export type ConsumptionCertificateState = {
  reason?: string;
  typeOfBuilding?: string;
  area?: number;
  yearOfConstruction?: number;
  reform?: string;
  numberOfApartments?: number;
  address?: string;
  postalCode?: string;
  location?: string;
  bundesland?: string;
  photoId?: string;
  photo?: File | null;
  hasHeatedBasement?: boolean;

  heatingSystemType?: string;
  yearOfHeatingInstalation?: number;
  monthOfBill?: string;
  endMonthOfBill?: string;
  yearOfBill?: number;
  verbrauch1?: number;
  verbrauch2?: number;
  verbrauch3?: number;
  secondaryHeatingSystemType?: string;
  secondaryYearOfHeatingInstalation?: number;
  secondaryVerbrauch1?: number;
  secondaryVerbrauch2?: number;
  secondaryVerbrauch3?: number;

  ventilationType?: string[];
  coolingSystems?: { system: string; surface?: number }[];
  coolingSystemsNumber?: string;
  coolingSystemsNominalOutput?: string;
  coolingSystemsNextInspection?: string;
  coolingSystemsHasBuildingAutomation?: boolean;
  renewable?: string[];
  renewableUsage?: string[];
  renovationMeasures?: string;

  hotWaterSystems: HotWaterSystem[];

  modernisationRecommendations?: ModernisationRecommendationState[];
};

type HotWaterSystem = {
  type: string;
  isPartOfHeating?: boolean;
  isCentralized?: boolean;
};

export type ConsumpCert = {
  id: string;
};
