import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Sophia from "./Sophia";
import { InsulationMaterials, Steps } from "./utils";
import ProgressBar from "../ProgressBar";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useImage from "../../../../hooks/useImage";
import NovoSelect from "../../../../components/common/NovoSelect";

export default function BasementScreen({ prev, next }: ScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const conditionalRendering = () => {
    if (isMobile) {
      return;
    }
    return (
      <>
        <Grid sx={{ px: 0 }}>
          <Divider orientation='vertical' />
        </Grid>
        <Grid sx={{ flexGrow: 1 }}>
          <Sophia step={Steps.outerWalls} />
        </Grid>
      </>
    );
  };

  return (
    <Grid
      container
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mb: 4,
        maxWidth: "100%",
      }}
    >
      <Grid xs={12}>
        <ProgressBar label={"Basement information"} value={35} />
      </Grid>
      <Grid xs={12} lg={8}>
        <Form prev={prev} next={next} />
      </Grid>
      {conditionalRendering()}
    </Grid>
  );
}

function Form({ prev, next }: ScreenProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(false);
  const [basement, setBasement] = useState("");
  const floorInsulation = useImage("House_sections_floor_insulation.png");
  const basementWallsInsulation = useImage("House_sections_basement_walls_insulation.png");
  const basementCeilingInsulation = useImage("House_sections_basement_ceiling_insulation.png");
  const basementFloorInsulation = useImage("House_sections_basement_floor_insulation.png");

  const renderDialogLink = () => {
    const toggleOpen = () => {
      setOpen(!open);
    };

    if (isDesktop) {
      return;
    }
    return (
      <Grid xs={12} lg={0} sx={{ mt: 2 }}>
        <Link
          variant='caption'
          onClick={toggleOpen}
          sx={{
            color: "text.primary",
            textDecorationColor: "rgba(0, 0, 0, 0.87)",
            fontWeight: "fontWeightMedium",
          }}
        >
          Warum benötigen wir diese Daten?
        </Link>
        <Dialog open={open} onClose={toggleOpen} fullScreen>
          <DialogTitle>
            <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={toggleOpen}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Sophia step={Steps.outerWalls} />
          </DialogContent>
        </Dialog>
      </Grid>
    );
  };

  return (
    <Grid
      container
      spacing={{
        xs: 2,
        lg: 4,
      }}
      sx={{
        ml: { lg: 4 },
        px: { xs: 2 },
        mx: { xs: 0, lg: "inherit" },
      }}
    >
      <Grid xs={12} sx={{ mb: 2 }}>
        <Typography variant='body1'>
          <strong>Screen 4: </strong>Basement information
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Stack direction='row' alignItems='baseline' spacing={2}>
          <Typography>Does your house have a basement? Is it heated?</Typography>
          <ToggleButtonGroup
            size='small'
            exclusive
            value={basement}
            onChange={(_, value) => {
              setBasement(value);
            }}
          >
            <ToggleButton value='heated'>Yes, heated</ToggleButton>
            <ToggleButton value='partially-heated'>Yes, partially heated</ToggleButton>
            <ToggleButton value='not-heated'>Yes, unheated</ToggleButton>
            <ToggleButton value='no'>No basement</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Grid>
      {(basement === "heated" || basement === "partially-heated" || basement === "no") && (
        <>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid xs={6}>
            <Stack flexGrow={1} alignItems='center' justifyItems='center' spacing={4}>
              <Typography fontWeight={"fontWeightBold"} variant='body2'>
                Insulation - House floor
              </Typography>
              <img src={floorInsulation.image} width={150} height={150} style={{ alignSelf: "center" }} />
            </Stack>
          </Grid>
          <Grid xs={6}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                variant='outlined'
                id='insulation-year-input'
                label='Insulation year'
                required={true}
                type='number'
                inputProps={{ min: 1900, max: new Date().getFullYear() }}
              />
              <TextField
                fullWidth
                variant='outlined'
                id='insulation-thickness-input'
                label='Insulation thickness'
                type='number'
                inputProps={{ min: 1, max: 25 }}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
                }}
              />
              <NovoSelect
                label={"Insulation material"}
                options={[InsulationMaterials.mineralWool, InsulationMaterials.stoneWool, InsulationMaterials.styrofoam, InsulationMaterials.woodFiber]}
                value={""}
              />
            </Stack>
          </Grid>
        </>
      )}
      {(basement === "heated" || basement === "partially-heated") && (
        <>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid xs={6}>
            <Stack flexGrow={1} alignItems='center' justifyItems='center' spacing={4}>
              <Typography fontWeight={"fontWeightBold"} variant='body2'>
                Insulation - Basement walls
              </Typography>
              <img src={basementWallsInsulation.image} width={150} height={150} style={{ alignSelf: "center" }} />
            </Stack>
          </Grid>
          <Grid xs={6}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                variant='outlined'
                id='insulation-year-input'
                label='Insulation year'
                required={true}
                type='number'
                inputProps={{ min: 1900, max: new Date().getFullYear() }}
              />
              <TextField
                fullWidth
                variant='outlined'
                id='insulation-thickness-input'
                label='Insulation thickness'
                type='number'
                inputProps={{ min: 1, max: 25 }}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
                }}
              />
              <NovoSelect
                label={"Insulation material"}
                options={[InsulationMaterials.mineralWool, InsulationMaterials.stoneWool, InsulationMaterials.styrofoam, InsulationMaterials.woodFiber]}
                value={""}
              />
            </Stack>
          </Grid>
        </>
      )}
      {(basement === "heated" || basement === "partially-heated") && (
        <>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid xs={6}>
            <Stack flexGrow={1} alignItems='center' justifyItems='center' spacing={4}>
              <Typography fontWeight={"fontWeightBold"} variant='body2'>
                Insulation - Basement floor
              </Typography>
              <img src={basementFloorInsulation.image} width={150} height={150} style={{ alignSelf: "center" }} />
            </Stack>
          </Grid>
          <Grid xs={6}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                variant='outlined'
                id='insulation-year-input'
                label='Insulation year'
                required={true}
                type='number'
                inputProps={{ min: 1900, max: new Date().getFullYear() }}
              />
              <TextField
                fullWidth
                variant='outlined'
                id='insulation-thickness-input'
                label='Insulation thickness'
                type='number'
                inputProps={{ min: 1, max: 25 }}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
                }}
              />
              <NovoSelect
                label={"Insulation material"}
                options={[InsulationMaterials.mineralWool, InsulationMaterials.stoneWool, InsulationMaterials.styrofoam, InsulationMaterials.woodFiber]}
                value={""}
              />
            </Stack>
          </Grid>
        </>
      )}
      {(basement === "not-heated" || basement === "partially-heated") && (
        <>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid xs={6}>
            <Stack flexGrow={1} alignItems='center' justifyItems='center' spacing={4}>
              <Typography fontWeight={"fontWeightBold"} variant='body2'>
                Insulation - Basement ceiling
              </Typography>
              <img src={basementCeilingInsulation.image} width={150} height={150} style={{ alignSelf: "center" }} />
            </Stack>
          </Grid>
          <Grid xs={6}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                variant='outlined'
                id='insulation-year-input'
                label='Insulation year'
                required={true}
                type='number'
                inputProps={{ min: 1900, max: new Date().getFullYear() }}
              />
              <TextField
                fullWidth
                variant='outlined'
                id='insulation-thickness-input'
                label='Insulation thickness'
                type='number'
                inputProps={{ min: 1, max: 25 }}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
                }}
              />
              <NovoSelect
                label={"Insulation material"}
                options={[InsulationMaterials.mineralWool, InsulationMaterials.stoneWool, InsulationMaterials.styrofoam, InsulationMaterials.woodFiber]}
                value={""}
              />
            </Stack>
          </Grid>
        </>
      )}
      {renderDialogLink()}
      <Grid xs={12} sx={{ mt: { xs: 2, lg: 8 } }}>
        <Stack direction={"row"} justifyContent='space-between'>
          <Button variant='text' color='secondary' onClick={prev}>
            Zurück
          </Button>
          <Button variant='contained' onClick={next}>
            Weiter
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}
