import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ProgressBar from "../ProgressBar";
import { Button, Stack, Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";

export default function IntroScreen({ next }: ScreenProps) {
  return (
    <Grid
      container
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mx: { xs: 0, lg: 10 },
        mb: 4,
        maxWidth: "100%",
      }}
    >
      <Grid xs={12}>
        <ProgressBar label={"Get ready!"} value={0} />
      </Grid>
      <Grid xs={12}>
        <Stack>
          <Typography>This questionnaire is a continuation of the initial Schnellcheck.</Typography>
          <Typography>
            In order to better assist you with your house renovation plan, we need to collect these information as accuretely and extensively as possible.
          </Typography>
          <Typography>
            Please make sure you have your blueprints with you, as well as information about the walls, roof and basement insulation. You will also be asked to
            upload some photos of your property.
          </Typography>
          <Typography>
            After the questionnaire, you will receive a personalized plan, based on your answers, and a detailed report about your house renovation.
          </Typography>
          <Typography>Once you are ready, click the button below to start. Thank you.</Typography>
          <Stack direction='row' spacing={2} sx={{ mt: 2, justifyContent: "center" }}>
            <Button variant='contained' onClick={next}>
              I am ready
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}
