import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { ConsumptionCertificate } from "../types/ConsumptionCertificateState";
import { apiEndpoint } from "../utils/utils";
import { AuthContext } from "../Contexts";

const API_ENDPOINT = `${apiEndpoint()}/consumption-certificate`;

export function useFetchConsumptionCertificate(id: string) {
  const [consumptionCert, setConsumptionCert] = useState<ConsumptionCertificate>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { token, logout } = useContext(AuthContext);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get<ConsumptionCertificate>(`${API_ENDPOINT}/${id}`, {
        headers: {
          Authorization: `Bearer ${token?.id_token}`,
        },
      });
      setConsumptionCert(data);
    } catch (e) {
      if (e.response.status === 401) {
        logout();
      }
    } finally {
      setIsLoading(false);
    }
  }, [id, logout, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    consumptionCert,
    isLoading,
  };
}

export function useFetchConsumptionCertificates() {
  const [consumptionCerts, setConsumptionCerts] = useState<ConsumptionCertificate[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageArgs, setPageArgs] = useState<{ page: number; pageSize: number }>({
    page: 0,
    pageSize: 10,
  });
  const [searchString, setSearchString] = useState<string>();
  const { token, logout } = useContext(AuthContext);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get<ConsumptionCertificate[]>(API_ENDPOINT, {
        headers: {
          Authorization: `Bearer ${token?.id_token}`,
        },
        params: {
          ...pageArgs,
          ...(searchString ? { searchString } : {}),
        },
      });
      setConsumptionCerts([...data]);
    } catch (e) {
      if (e.response.status === 401) {
        logout();
      }
    } finally {
      setIsLoading(false);
    }
  }, [logout, pageArgs, searchString, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    consumptionCerts,
    isLoading,
    pageArgs,
    fetchData,
    setPageArgs,
    setSearchString,
  };
}
