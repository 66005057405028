import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Sophia from "./Sophia";
import ProgressBar from "../ProgressBar";
import { ScreenProps } from "./StatusQuoFunnel";
import useImage from "../../../../hooks/useImage";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";
import { Steps } from "../../../../types/cockpit/types";
import usePutMetric from "../../../../hooks/usePutMetric";

export default function Screen3({ schnellcheck, setSchnellcheck, isUpdateRunning, prev, saveAndNext }: ScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [putMetric] = usePutMetric();

  useEffect(() => {
    putMetric("Schnellcheck", "RenderInsulationPage");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const conditionalRendering = () => {
    if (isMobile) {
      return;
    }
    return (
      <>
        <Grid sx={{ px: 0 }}>
          <Divider orientation='vertical' />
        </Grid>
        <Grid sx={{ flexGrow: 1 }}>
          <Sophia step={Steps.insulation} />
        </Grid>
      </>
    );
  };

  return (
    <Grid
      container
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mx: { xs: 0, lg: 10 },
        backgroundColor: "#f6f6f6",
        mb: 4,
        maxWidth: "100%",
      }}
    >
      <Grid xs={12}>
        <ProgressBar label={"40% geschafft"} value={40} />
      </Grid>
      <Grid xs={12} lg={8}>
        <Form schnellcheck={schnellcheck} setSchnellcheck={setSchnellcheck} isUpdateRunning={isUpdateRunning} prev={prev} saveAndNext={saveAndNext} />
      </Grid>
      {conditionalRendering()}
    </Grid>
  );
}

function Form({ schnellcheck, setSchnellcheck, isUpdateRunning, prev, saveAndNext }: ScreenProps) {
  const { yearOfBasementInsulation, yearOfWallsInsulation, yearOfRoofInsulation } = schnellcheck.answers?.houseInsulation ?? {};
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const roof = useImage("isfp-icons/roof.png");
  const walls = useImage("isfp-icons/walls.png");
  const basement = useImage("isfp-icons/basement.png");
  const [open, setOpen] = useState(false);
  const [isRoofSelected, setRoofSelected] = useState<boolean>(!!yearOfRoofInsulation);
  const [roofYearError, setRoofYearError] = useState<string>();
  const [isWallsSelected, setWallsSelected] = useState<boolean>(!!yearOfWallsInsulation);
  const [wallsYearError, setWallsYearError] = useState<string>();
  const [isBasementSelected, setBasementSelected] = useState<boolean>(!!yearOfBasementInsulation);
  const [basementYearError, setBasementYearError] = useState<string>();

  const buildingYear = schnellcheck?.answers?.houseDetails?.yearOfConstruction || 1800;
  const currentYear = new Date().getFullYear();

  const toggleRoofSelected = () => {
    if (isRoofSelected) {
      updateRoofYear();
    }
    setRoofSelected(!isRoofSelected);
  };

  const updateRoofYear = (value?: string) => {
    const yearOfRoofInsulation = value && /^[0-9]+$/.test(value) ? Number.parseInt(value) : undefined;
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseInsulation: {
          ...schnellcheck.answers?.houseInsulation,
          yearOfRoofInsulation,
        },
      },
    });
    if (yearOfRoofInsulation) {
      setRoofYearError(undefined);
    }
  };

  const toggleWallsSelected = () => {
    if (isWallsSelected) {
      updateWallsYear();
    }
    setWallsSelected(!isWallsSelected);
  };

  const updateWallsYear = (value?: string) => {
    const yearOfWallsInsulation = value && /^[0-9]+$/.test(value) ? Number.parseInt(value) : undefined;
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseInsulation: {
          ...schnellcheck.answers?.houseInsulation,
          yearOfWallsInsulation,
        },
      },
    });
    if (yearOfWallsInsulation) {
      setWallsYearError(undefined);
    }
  };

  const toggleBasementSelected = () => {
    if (isBasementSelected) {
      updateBasementYear();
    }
    setBasementSelected(!isBasementSelected);
  };

  const updateBasementYear = (value?: string) => {
    const yearOfBasementInsulation = value && /^[0-9]+$/.test(value) ? Number.parseInt(value) : undefined;
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseInsulation: {
          ...schnellcheck.answers?.houseInsulation,
          yearOfBasementInsulation,
        },
      },
    });
    if (yearOfBasementInsulation) {
      setBasementYearError(undefined);
    }
  };

  const validate = () => {
    const { yearOfBasementInsulation, yearOfWallsInsulation, yearOfRoofInsulation } = schnellcheck.answers?.houseInsulation ?? {};
    if (isRoofSelected) {
      if (!yearOfRoofInsulation) {
        setRoofYearError("Pflichtfeld");
        return false;
      }
      if (yearOfRoofInsulation < buildingYear || yearOfRoofInsulation > currentYear) {
        setRoofYearError(`Das Jahr muss zwischen ${buildingYear} und ${currentYear} liegen`);
        return false;
      }
      setRoofYearError(undefined);
    }

    if (isWallsSelected) {
      if (!yearOfWallsInsulation) {
        setWallsYearError("Pflichtfeld");
        return false;
      }
      if (yearOfWallsInsulation < buildingYear || yearOfWallsInsulation > currentYear) {
        setWallsYearError(`Das Jahr muss zwischen ${buildingYear} und ${currentYear} liegen`);
        return false;
      }
      setWallsYearError(undefined);
    }

    if (isBasementSelected) {
      if (!yearOfBasementInsulation) {
        setBasementYearError("Pflichtfeld");
        return false;
      }
      if (yearOfBasementInsulation < buildingYear || yearOfBasementInsulation > currentYear) {
        setBasementYearError(`Das Jahr muss zwischen ${buildingYear} und ${currentYear} liegen`);
        return false;
      }
      setBasementYearError(undefined);
    }
    return true;
  };

  useEffect(() => {
    const { yearOfBasementInsulation, yearOfWallsInsulation, yearOfRoofInsulation } = schnellcheck.answers?.houseInsulation ?? {};
    setBasementSelected(!!yearOfBasementInsulation || isBasementSelected);
    setWallsSelected(!!yearOfWallsInsulation || isWallsSelected);
    setRoofSelected(!!yearOfRoofInsulation || isRoofSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schnellcheck]);

  const renderDialogLink = () => {
    const toggleOpen = () => {
      setOpen(!open);
    };

    if (isDesktop) {
      return;
    }
    return (
      <Grid xs={12} lg={0} sx={{ mt: 2 }}>
        <Link
          variant='caption'
          onClick={toggleOpen}
          sx={{
            color: "text.primary",
            textDecorationColor: "rgba(0, 0, 0, 0.87)",
            fontWeight: "fontWeightMedium",
          }}
        >
          Warum benötigen wir diese Daten?
        </Link>
        <Dialog open={open} onClose={toggleOpen} fullScreen>
          <DialogTitle>
            <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={toggleOpen}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Sophia step={Steps.insulation} />
          </DialogContent>
        </Dialog>
      </Grid>
    );
  };
  return (
    <Grid
      container
      spacing={{
        xs: 2,
        lg: 4,
      }}
      sx={{
        ml: { lg: 4 },
        px: { xs: 2 },
        mx: { xs: 0, lg: "inherit" },
      }}
    >
      <Grid xs={12} sx={{ mb: { xs: 0, lg: 4 } }}>
        <Typography variant='body1'>
          <strong>Schritt 3: </strong>Verfügt Ihr Haus über Dämmung? (bitte geben Sie das letzte Datum von Dämmmaßnahmen an)
        </Typography>
      </Grid>
      <Grid xs={12} md={6}>
        <Stack
          component={Paper}
          direction={"row"}
          spacing={2}
          alignItems='center'
          sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", backgroundColor: isRoofSelected ? "" : "inherit" }}
          onClick={toggleRoofSelected}
        >
          <Stack alignItems={"start"} spacing={1}>
            <img src={roof.image} height={60} width={60} style={{ marginLeft: "auto", marginRight: "auto" }} />
            <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
              Dach
            </Typography>
          </Stack>
          <Collapse orientation='horizontal' in={isRoofSelected}>
            <FormControl
              sx={{ flexGrow: 2 }}
              onClick={(evt) => {
                evt.stopPropagation();
              }}
            >
              <TextField
                variant='outlined'
                id='roof-year-input'
                data-cy='roof-year-input'
                value={schnellcheck.answers?.houseInsulation?.yearOfRoofInsulation}
                label='Jahr (Circa)'
                onChange={(event) => updateRoofYear(event.target.value)}
                required={true}
                type='number'
                inputProps={{ min: buildingYear, max: currentYear }}
                error={!!roofYearError}
                helperText={roofYearError}
                sx={{
                  "& > label.Mui-focused": {
                    color: "text.primary",
                  },
                  "& > div": {
                    color: "text.primary",
                    backgroundColor: "rgba(255, 255, 255, 0.10)",
                  },
                  "& > .MuiOutlinedInput-root.Mui-focused > fieldset": {
                    color: "text.primary",
                    borderColor: "rgba(0 ,0, 0, 0.87)",
                  },
                }}
                InputLabelProps={{ shrink: !!schnellcheck.answers?.houseInsulation?.yearOfRoofInsulation }}
              />
            </FormControl>
          </Collapse>
          <Stack sx={{ flexGrow: 1, alignItems: "flex-end" }}>
            <Checkbox data-cy='roof-input' sx={{ "&.Mui-checked": { color: "text.primary" } }} onChange={toggleRoofSelected} checked={isRoofSelected} />
          </Stack>
        </Stack>
      </Grid>
      <Grid xs={12} md={6}>
        <Stack
          component={Paper}
          direction={"row"}
          spacing={2}
          alignItems='center'
          sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", backgroundColor: isWallsSelected ? "" : "inherit" }}
          onClick={toggleWallsSelected}
        >
          <Stack alignItems={"start"} spacing={1}>
            <img src={walls.image} height={60} width={60} style={{ marginLeft: "auto", marginRight: "auto" }} />
            <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
              Wände
            </Typography>
          </Stack>
          <Collapse orientation='horizontal' in={isWallsSelected}>
            <FormControl
              sx={{ flexGrow: 2 }}
              onClick={(evt) => {
                evt.stopPropagation();
              }}
            >
              <TextField
                variant='outlined'
                id='walls-year-input'
                data-cy='walls-year-input'
                value={schnellcheck.answers?.houseInsulation?.yearOfWallsInsulation}
                label='Jahr (Circa)'
                onChange={(event) => updateWallsYear(event.target.value)}
                required={true}
                type='number'
                inputProps={{ min: buildingYear, max: currentYear }}
                error={!!wallsYearError}
                helperText={wallsYearError}
                sx={{
                  "& > label.Mui-focused": {
                    color: "text.primary",
                  },
                  "& > div": {
                    color: "text.primary",
                    backgroundColor: "rgba(255, 255, 255, 0.10)",
                  },
                  "& > .MuiOutlinedInput-root.Mui-focused > fieldset": {
                    color: "text.primary",
                    borderColor: "rgba(0 ,0, 0, 0.87)",
                  },
                }}
                InputLabelProps={{ shrink: !!schnellcheck.answers?.houseInsulation?.yearOfWallsInsulation }}
              />
            </FormControl>
          </Collapse>
          <Stack sx={{ flexGrow: 1, alignItems: "flex-end" }}>
            <Checkbox data-cy='walls-input' sx={{ "&.Mui-checked": { color: "text.primary" } }} onChange={toggleWallsSelected} checked={isWallsSelected} />
          </Stack>
        </Stack>
      </Grid>
      <Grid xs={12} md={6}>
        <Stack
          component={Paper}
          direction={"row"}
          spacing={2}
          alignItems='center'
          sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", backgroundColor: isBasementSelected ? "" : "inherit" }}
          onClick={toggleBasementSelected}
        >
          <Stack alignItems={"start"} spacing={1}>
            <img src={basement.image} height={60} width={60} style={{ marginLeft: "auto", marginRight: "auto" }} />
            <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
              Kellerdecke
            </Typography>
          </Stack>
          <Collapse orientation='horizontal' in={isBasementSelected}>
            <FormControl
              sx={{ flexGrow: 2 }}
              onClick={(evt) => {
                evt.stopPropagation();
              }}
            >
              <TextField
                variant='outlined'
                id='basement-year-input'
                data-cy='basement-year-input'
                value={schnellcheck.answers?.houseInsulation?.yearOfBasementInsulation}
                label='Jahr (Circa)'
                onChange={(event) => updateBasementYear(event.target.value)}
                required={true}
                type='number'
                inputProps={{ min: buildingYear, max: currentYear }}
                error={!!basementYearError}
                helperText={basementYearError}
                sx={{
                  "& > label.Mui-focused": {
                    color: "text.primary",
                  },
                  "& > div": {
                    color: "text.primary",
                    backgroundColor: "rgba(255, 255, 255, 0.10)",
                  },
                  "& > .MuiOutlinedInput-root.Mui-focused > fieldset": {
                    color: "text.primary",
                    borderColor: "rgba(0 ,0, 0, 0.87)",
                  },
                }}
                InputLabelProps={{ shrink: !!schnellcheck.answers?.houseInsulation?.yearOfBasementInsulation }}
              />
            </FormControl>
          </Collapse>
          <Stack sx={{ flexGrow: 1, alignItems: "flex-end" }}>
            <Checkbox
              data-cy='basement-input'
              sx={{ "&.Mui-checked": { color: "text.primary" } }}
              onChange={toggleBasementSelected}
              checked={isBasementSelected}
            />
          </Stack>
        </Stack>
      </Grid>
      {renderDialogLink()}
      <Grid xs={12} sx={{ mt: { xs: 2, lg: 8 } }}>
        <Stack direction={"row"} alignItems={"flex-start"}>
          <Button variant='text' color='secondary' onClick={prev}>
            ZURÜCK
          </Button>
          <Box sx={{ flexGrow: 1 }}>
            <ButtonWithSpinner
              variant='contained'
              sx={{ float: "inline-end" }}
              loading={isUpdateRunning}
              data-cy='screen-3-next-btn'
              onClick={() => {
                if (validate()) {
                  saveAndNext();
                }
              }}
              label={"WEITER"}
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}
