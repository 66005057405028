import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Sophia from "./Sophia";
import { Steps, WindowFrameMaterial } from "./utils";
import ProgressBar from "../ProgressBar";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import NovoSelect from "../../../../components/common/NovoSelect";

export default function DoorsAndWindowsScreen({ prev, next }: ScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const conditionalRendering = () => {
    if (isMobile) {
      return;
    }
    return (
      <>
        <Grid sx={{ px: 0 }}>
          <Divider orientation='vertical' />
        </Grid>
        <Grid sx={{ flexGrow: 1 }}>
          <Sophia step={Steps.doorsWindows} />
        </Grid>
      </>
    );
  };

  return (
    <Grid
      container
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mb: 4,
        maxWidth: "100%",
      }}
    >
      <Grid xs={12}>
        <ProgressBar label={"Doors & windows"} value={45} />
      </Grid>
      <Grid xs={12} lg={8}>
        <Form prev={prev} next={next} />
      </Grid>
      {conditionalRendering()}
    </Grid>
  );
}

function Form({ prev, next }: ScreenProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(false);
  const [multiWindowTypes, setMultiWindowTypes] = useState("");

  const renderDialogLink = () => {
    const toggleOpen = () => {
      setOpen(!open);
    };

    if (isDesktop) {
      return;
    }
    return (
      <Grid xs={12} lg={0} sx={{ mt: 2 }}>
        <Link
          variant='caption'
          onClick={toggleOpen}
          sx={{
            color: "text.primary",
            textDecorationColor: "rgba(0, 0, 0, 0.87)",
            fontWeight: "fontWeightMedium",
          }}
        >
          Warum benötigen wir diese Daten?
        </Link>
        <Dialog open={open} onClose={toggleOpen} fullScreen>
          <DialogTitle>
            <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={toggleOpen}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Sophia step={Steps.outerWalls} />
          </DialogContent>
        </Dialog>
      </Grid>
    );
  };

  return (
    <Grid
      container
      spacing={{
        xs: 2,
        lg: 4,
      }}
      sx={{
        ml: { lg: 4 },
        px: { xs: 2 },
        mx: { xs: 0, lg: "inherit" },
      }}
    >
      <Grid xs={12} sx={{ mb: 2 }}>
        <Typography variant='body1'>
          <strong>Screen 5: </strong>Doors & windows information
        </Typography>
      </Grid>
      <Grid xs={6}>
        <NovoSelect
          label={"Window frame material"}
          options={[WindowFrameMaterial.wood, WindowFrameMaterial.metal, WindowFrameMaterial.plastic]}
          value={""}
          required
        />
      </Grid>
      <Grid xs={6}>
        <Stack direction='row' alignItems='baseline' spacing={2}>
          <Typography>Do you have other types of windows?</Typography>
          <ToggleButtonGroup size='small' exclusive value={multiWindowTypes} onChange={(event, value) => setMultiWindowTypes(value)}>
            <ToggleButton value='yes'>Yes</ToggleButton>
            <ToggleButton value='no'>No</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Grid>
      <Grid xs={6}>
        <TextField
          fullWidth
          variant='outlined'
          id='windows-installation-year-input'
          label='Windows installation year'
          required={true}
          type='number'
          inputProps={{ min: 1900, max: new Date().getFullYear() }}
        />
      </Grid>
      <Grid xs={6}>
        <TextField
          fullWidth
          variant='outlined'
          id='doors-installation-year-input'
          label='Doors installation year'
          required={true}
          type='number'
          inputProps={{ min: 1900, max: new Date().getFullYear() }}
        />
      </Grid>
      {renderDialogLink()}
      <Grid xs={12} sx={{ mt: { xs: 2, lg: 8 } }}>
        <Stack direction={"row"} justifyContent='space-between'>
          <Button variant='text' color='secondary' onClick={prev}>
            Zurück
          </Button>
          <Button variant='contained' onClick={next}>
            Weiter
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}
