import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Sophia from "./Sophia";
import { InsulationMaterials, Steps } from "./utils";
import ProgressBar from "../ProgressBar";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useImage from "../../../../hooks/useImage";
import NovoSelect from "../../../../components/common/NovoSelect";

export default function AtticScreen({ prev, next }: ScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const conditionalRendering = () => {
    if (isMobile) {
      return;
    }
    return (
      <>
        <Grid sx={{ px: 0 }}>
          <Divider orientation='vertical' />
        </Grid>
        <Grid sx={{ flexGrow: 1 }}>
          <Sophia step={Steps.outerWalls} />
        </Grid>
      </>
    );
  };

  return (
    <Grid
      container
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mb: 4,
        maxWidth: "100%",
      }}
    >
      <Grid xs={12}>
        <ProgressBar label={"Attic information"} value={25} />
      </Grid>
      <Grid xs={12} lg={8}>
        <Form prev={prev} next={next} />
      </Grid>
      {conditionalRendering()}
    </Grid>
  );
}

function Form({ prev, next }: ScreenProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(false);
  const [attic, setAttic] = useState("");
  const roofInsulation = useImage("House_sections_roof_insulation.png");
  const ceilingInsulation = useImage("House_sections_ceiling_insulation.png");
  const illustration = attic === "not-heated" ? ceilingInsulation : roofInsulation;

  const renderDialogLink = () => {
    const toggleOpen = () => {
      setOpen(!open);
    };

    if (isDesktop) {
      return;
    }
    return (
      <Grid xs={12} lg={0} sx={{ mt: 2 }}>
        <Link
          variant='caption'
          onClick={toggleOpen}
          sx={{
            color: "text.primary",
            textDecorationColor: "rgba(0, 0, 0, 0.87)",
            fontWeight: "fontWeightMedium",
          }}
        >
          Warum benötigen wir diese Daten?
        </Link>
        <Dialog open={open} onClose={toggleOpen} fullScreen>
          <DialogTitle>
            <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={toggleOpen}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Sophia step={Steps.outerWalls} />
          </DialogContent>
        </Dialog>
      </Grid>
    );
  };

  return (
    <Grid
      container
      spacing={{
        xs: 2,
        lg: 4,
      }}
      sx={{
        ml: { lg: 4 },
        px: { xs: 2 },
        mx: { xs: 0, lg: "inherit" },
      }}
    >
      <Grid xs={12} sx={{ mb: 2 }}>
        <Typography variant='body1'>
          <strong>Screen 3: </strong>Attic information
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Stack direction='row' alignItems='baseline' spacing={2}>
          <Typography>Does your house have an attic? Is it heated?</Typography>
          <ToggleButtonGroup
            size='small'
            exclusive
            value={attic}
            onChange={(_, value) => {
              setAttic(value);
            }}
          >
            <ToggleButton value='heated'>Yes, heated</ToggleButton>
            <ToggleButton value='not-heated'>Yes, unheated</ToggleButton>
            <ToggleButton value='no'>No attic</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Grid>
      {attic && (
        <>
          <Grid xs={6}>
            <Stack flexGrow={1} alignItems='center' justifyItems='center'>
              <img src={illustration.image} width={150} height={150} style={{ alignSelf: "center" }} />
            </Stack>
          </Grid>
          <Grid xs={6}>
            <Stack spacing={2} mt={2}>
              <Typography>{attic === "not-heated" ? "Ceiling / attic floor" : "Roof"} Insulation </Typography>
              <TextField
                fullWidth
                variant='outlined'
                id='insulation-year-input'
                label='Insulation year'
                required={true}
                type='number'
                inputProps={{ min: 1900, max: new Date().getFullYear() }}
              />
            </Stack>
          </Grid>
          <Grid xs={6}>
            <TextField
              fullWidth
              variant='outlined'
              id='insulation-thickness-input'
              label='Insulation thickness'
              type='number'
              inputProps={{ min: 1, max: 25 }}
              InputProps={{
                endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
              }}
            />
          </Grid>
          <Grid xs={6}>
            <NovoSelect
              label={"Insulation material"}
              options={[InsulationMaterials.mineralWool, InsulationMaterials.stoneWool, InsulationMaterials.styrofoam, InsulationMaterials.woodFiber]}
              value={""}
            />
          </Grid>
        </>
      )}
      {renderDialogLink()}
      <Grid xs={12} sx={{ mt: { xs: 2, lg: 8 } }}>
        <Stack direction={"row"} justifyContent='space-between'>
          <Button variant='text' color='secondary' onClick={prev}>
            Zurück
          </Button>
          <Button variant='contained' onClick={next}>
            Weiter
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}
