import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useRef, useState } from "react";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

interface ImageUploadProps extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "onChange"> {
  unableToProceed?: boolean;
  required?: boolean;
  error?: boolean;
  onChange?: (value: File | undefined) => void;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function NovoImageUpload({ onChange, required, error, unableToProceed, ...props }: ImageUploadProps) {
  const [image, setImage] = useState<string>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imgFile = e.target.files?.item(0);
    if (!imgFile) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      if (typeof e.target?.result !== "string") {
        return;
      }
      setImage(e.target.result || "");
    };
    reader.readAsDataURL(imgFile);
    if (onChange) {
      onChange(imgFile);
    }
  };

  const handleDelete = () => {
    setImage("");
    if (onChange) {
      onChange(undefined);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={4}>
        <Button
          component='label'
          role={undefined}
          variant='outlined'
          color={error ? "error" : "secondary"}
          disabled={unableToProceed}
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Foto hochladen {required && <sup>*</sup>}
          <VisuallyHiddenInput ref={fileInputRef} type='file' onChange={handleUpload} accept='image/*' required={required} {...props} />
        </Button>
      </Grid>
      {image && (
        <Grid xs={12} md={8}>
          <Box
            sx={{
              border: "dashed 2px #dadada",
              borderRadius: "8px",
            }}
          >
            <Box
              component='img'
              sx={{
                maxHeight: { xs: 150, md: 300 },
                maxWidth: { xs: 300, md: 400 },
                objectFit: "contain",
                verticalAlign: "top",
                margin: "1rem",
                boxShadow: 2,
              }}
              alt='Gebäudeansicht'
              src={image}
            />
            <IconButton
              aria-label='delete'
              onClick={handleDelete}
              sx={{
                color: "text.secondary",
                my: "1rem",
              }}
              disabled={unableToProceed}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
